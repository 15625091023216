@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./styles/overrides";
@import "./styles/animation";

body {
  font-family: "Roboto";
}

@layer base {
  html {
    @apply text-blue-300;
  }
}

.fa-icon-div {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f7f9;
  border-radius: 0.25rem;
}

.modal-bg-black .mat-dialog-container {
  background-color: #000000;
}

.breakWords {
  overflow-wrap: anywhere;
  white-space: break-words;
}
