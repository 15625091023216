.square-checkbox {
  ::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #0061f3 !important;
    margin: 3px;
  }

  ::ng-deep .mat-checkbox-layout .mat-checkbox-label {
    font-size: 0.875rem;
    padding-left: 0.375rem;
    white-space: break-spaces !important;
  }

  ::ng-deep .mat-checkbox-checkmark {
    display: none;
  }

  ::ng-deep .mat-checkbox-frame {
    border-color: #e8ebee !important;
    border-width: 1px !important;
    background-color: #f5f7f9 !important;
  }

  ::ng-deep .mat-checkbox:hover {
    ::ng-deep .mat-checkbox-frame {
      border-color: #0061f3 !important;
    }
  }

  ::ng-deep .mat-checkbox-disabled {
    ::ng-deep .mat-checkbox-frame {
      border-color: #e8ebee !important;
    }
  }

  ::ng-deep .mat-checkbox-disabled:hover {
    ::ng-deep .mat-checkbox-frame {
      border-color: #e8ebee !important;
    }
  }

  ::ng-deep .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background {
    background-color: #bbbbbb !important;
  }
}

::ng-deep .mat-mdc-snack-bar-container .mat-mdc-snackbar-surface {
  background-color: transparent !important;
}

.blue-snackbar {
  background-color: #5a73ce;
}

.green-snackbar {
  background-color: #00d46a !important;
}

.red-snackbar {
  background-color: #d9534f !important;
}
